import { useTranslation } from 'react-i18next';

import companyConfigs from '../../../../constants/company';
import Link from '../../Link/Link';

const { withTermsOfUsePage, withImpressumPage, withPrivacyPolicyGdprPage, withPrivacyPolicyPage } =
    companyConfigs?.webPages;

type BoilerplateImprintProps = {
    handleNavigationClick: (transKey: string, transKeyFallback: string) => void;
};

const BoilerplateImprint = ({ handleNavigationClick }: BoilerplateImprintProps) => {
    const { t } = useTranslation('websiteV2');

    return (
        <ul className="pagefooter__boilerplate-imprint">
            {withPrivacyPolicyGdprPage && (
                <li>
                    <Link
                        to="/recruitment-privacy-policy-gdpr"
                        className="pagefooter__mini-link"
                        clickHandler={handleNavigationClick('/recruitment-privacy-policy-gdpr', 'privacyPolicy')}
                    >
                        {t('homepage-shared-footer-privacy', 'Privacy Policy')}
                    </Link>
                </li>
            )}

            {withPrivacyPolicyPage && (
                <li>
                    <Link
                        to="/privacy-policy"
                        className="pagefooter__mini-link"
                        clickHandler={handleNavigationClick('/privacy-policy', 'privacyPolicy')}
                    >
                        {t('homepage-shared-footer-privacy', 'Privacy Policy')}
                    </Link>
                </li>
            )}

            {withTermsOfUsePage && (
                <li>
                    <Link
                        to="/terms-of-service"
                        className="pagefooter__mini-link"
                        clickHandler={handleNavigationClick('/terms-of-service', 'termsOfService')}
                    >
                        {t('homepage-shared-footer-terms', 'Terms of Service')}
                    </Link>
                </li>
            )}
            {withImpressumPage && (
                <li>
                    <Link
                        to="/impressum"
                        className="pagefooter__mini-link"
                        clickHandler={handleNavigationClick('/impressum', 'impressum')}
                    >
                        Impressum
                    </Link>
                </li>
            )}
        </ul>
    );
};

export default BoilerplateImprint;
